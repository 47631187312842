import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

// 验证是否开通直播
export const checkOpenStatus = data => {
  return api({
    url: '/admin/wx_open/wx_live/isOpen',
    method: 'post',
    data,
    notCancel: true
  });
};

// 直播列表数据
export const detailList = data => {
  return api({
    url: '/admin/wx_open/wx_live/index',
    method: 'post',
    data
  });
};

// 开通小程序直播
export const openLive = data => {
  return api({
    url: '/admin/wx_open/wx_live/openLive',
    method: 'post',
    data
  });
};

// 关闭小程序直播
export const closeLive = data => {
  return api({
    url: '/admin/wx_open/wx_live/closeLive',
    method: 'post',
    data
  });
};

// 拉取最新列表数据
export const newLiveList = data => {
  return api({
    url: '/admin/wx_open/wx_live/getLive',
    method: 'post',
    data
  });
};

// 拉取最新视频数据
export const playbackList = data => {
  return api({
    url: '/admin/wx_open/wx_live/playbackLive',
    method: 'post',
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: '/admin/wx_open/wx_live/updateSort',
    method: 'post',
    data
  });
};

//更新推荐
export const updateRecommend = data => {
  return api({
    url: '/admin/wx_open/wx_live/updateIsRecommend',
    method: 'post',
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/wx_open/wx_live/updateIsShow",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/wx_open/wx_live/del",
    method: "post",
    data
  });
};

// 上传素材图
export const uploadImg = data => {
  return api({
    url: "/admin/wx_open/wx_live/material",
    method: "post",
    data
  });
};

// 获取回放视频列表
export const reviewkList = data => {
  return api({
    url: "/admin/wx_open/wx_live/playback",
    method: "post",
    data
  });
};

// 设为主视频
export const setMainVideo = data => {
  return api({
    url: "/admin/wx_open/wx_live/selected",
    method: "post",
    data
  });
};

// 保存功能配置
export const saveConfig = data => {
  return api({
    url: "/admin/wx_open/wx_live/config",
    method: "post",
    data
  });
};

export const getIndexData = () => {
  return api({
    url: '/admin/app_design/project/index',
    method: 'post'
  })
}

// 直播列表导出
export const documentWxLiveList = data => {
  return fetchFile({
    url: "/admin/wx_open/WxLive/export",
    method: "post",
    data,
    download: true
  });
};