<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true" collapseHeight="120"
    :onExport="isGov?null:onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="80px" size="medium"
      class="filter-from">

      <div class="filter-item">
        <el-form-item label="直播间名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入直播间名称"></el-input>
        </el-form-item>
        <el-form-item label="主播名称：" prop="anchor_name">
          <el-input v-model="form.anchor_name" placeholder="请输入主播名称"></el-input>
        </el-form-item>
        <el-form-item label="直播状态：" prop="live_status">
          <el-select v-model="live_status" placeholder="请选择直播状态">
            <el-option v-for="item in liveStatusArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="封面图：" prop="is_material">
          <el-select v-model="is_material" placeholder="请选择素材图状态">
            <el-option v-for="item in materialStatusArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直播类型：" prop="live_type">
          <el-select v-model="live_type" placeholder="请选择直播类型">
            <el-option v-for="item in liveTypeArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="开始时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentWxLiveList } from "../api/live-list";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      liveStatusArr: [
        { id: 101, name: "直播中" },
        { id: 102, name: "未开始" },
        { id: 103, name: "已结束" },
        { id: 104, name: "禁播" },
        { id: 105, name: "暂停" },
        { id: 106, name: "异常" },
        { id: 107, name: "已过期" },
      ],
      materialStatusArr: [
        { id: 0, name: "未上传" },
        { id: 1, name: "已上传" },
      ],
      liveTypeArr: [
        { id: 0, name: "手机直播" },
        { id: 1, name: "推流" },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      documentWxLiveList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    is_material: {
      get() {
        return this.form.is_material == -1 ? "" : this.form.is_material;
      },
      set(val) {
        this.form.is_material = val;
      },
    },
    live_type: {
      get() {
        return this.form.live_type == -1 ? "" : this.form.live_type;
      },
      set(val) {
        this.form.live_type = val;
      },
    },
    live_status: {
      get() {
        return this.form.live_status == -1 ? "" : this.form.live_status;
      },
      set(val) {
        this.form.live_status = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
