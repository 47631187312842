<template>
  <div>
    <list-layout ref="govJournal" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <live-list-filter is-gov :filterForm="filterForm" :uploadFilter="ok" />
      </template>
<!--      <template #item_sort="{row}">{{row.sort}}</template>-->
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovLives } from "@/modules/gov/api/live-list";
import LiveListFilter from "@/modules/wechat-app/components/LiveListFilter";

export default {
  components: {LiveListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        live_status: -1,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        name: "", //会刊名称
        year: "", //年份
        tab: "normal", //当前筛选tab
        page_size: 15, //每页数据量
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        // {
        //   label: "操作", type: "operation", width: 80, operations: [{command: 'edit', text: '查看'}]
        // }
        { label: "直播间ID", prop: "room_id", minWidth: 80 },
        { label: "直播间名称", prop: "name", minWidth: 150 },
        { label: "主播名称", prop: "anchor_name", minWidth: 110 },
        {
          label: "直播状态",
          prop: "live_status_text",
          minWidth: 110,
        },
        {
          label: "直播间推流地址",
          prop: "push_stream_address",
          minWidth: 250,
        },
        {
          label: "开始时间",
          prop: "start_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "计划结束时间",
          prop: "end_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "直播类型",
          prop: "live_type_text",
          minWidth: 110,
        },
        {
          label: "封面图",
          prop: "is_upload",
          minWidth: 90,
        },
        // {
        //   label: "回放功能",
        //   prop: "is_upload",
        //   minWidth: 90,
        // },
        {
          label: "回放视频",
          prop: "playback_count",
          minWidth: 150,
        },
        // { label: "排序", prop: "sort", type: "slot", minWidth: 60 },
        // { label: "推荐", prop: "is_recommend", minWidth: 60 },
        // { label: "显示", prop: "is_show", minWidth: 60 },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovLives(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govJournal.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
